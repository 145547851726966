<template>
	<div class="w-full">
		<div class="card-body">
			<div class="flex flex-col md:flex-row px-4 py-3 rounded-tl-lg rounded-tr-lg gap-4 md:justify-end md:items-center bg-white">
				<date-picker v-model="date" valueType="format"></date-picker>
				<div class="flex items-center gap-2">
					<label class="m-0 text-sm text-[#6E717C]">Service option</label>
					<select v-model="sharing_type" class="border-0 font-medium">
					<option value="">All services</option>
					<option value="exclusive">Exclusive</option>
					<option value="shared">Shared</option>
					</select>
				</div>
			</div>
			<b-table ref="staffTable" @row-clicked="viewRequest" :current-page="currentPage" :busy="loading" responsive striped :items="requests" :fields="fields">
				<template #table-busy>
				<div class="text-center text-secondary my-2">
					<strong>Loading...</strong>
				</div>
				</template>
				<template #empty>
					<h4>There are no request found</h4>
				</template>
				<template #cell(id)="row">
					{{ row.index+1+(perPage*(currentPage-1)) }}
				</template>
				<template #cell(created_at)="data">
					{{moment(data.item.created_at).format('DD-MM-YYYY') || 'N/A'}}
				</template>
				<template #cell(service_type)="data">
				<p class="whitespace-nowrap text-sm">{{ data.item.service_type == 'pay_per_seat' ? 'Pay per seat' : 'Pay per vehicle' }}</p>
				</template>
				<template #cell(start_date)="data">
					<p class="whitespace-nowrap">{{moment(data.item.start_date).format('DD-MM-YYYY') || 'N/A'}}</p>
				</template>
				<template #cell(status)="data">
				<p class="whitespace-nowrap text-sm px-2 rounded py-1 text-white w-fit" 
					:class="[data.item.status == 'active' ? ' bg-[#109352]' : ' bg-[#E13D45]']"
				>
					{{ data.item.status == 'active' ? 'Active' : data.item.status == 'deactivated' ? 'Deactivated' : 'Inactive' }}
				</p>
				</template>
			</b-table>
			<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
		</div>
	</div>
</template>

<script setup>
import {ref, defineProps, watch} from 'vue'
import router from '@/router'
import { axiosInstance } from '@/plugins/axios'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const props = defineProps(['companyId'])
const loading = ref(false)
const requests = ref()
const currentPage = ref(1)
const perPage = ref(10)
const totalRecords = ref()
const fields = [
  { key: 'id', label: 'S/N' },
  { key: 'name', label: 'ID' },
  { key: 'corporate.corporate_name', label: 'Company' },
  { key: 'created_at', label: 'Date Created' },
  { key: 'service_type', label: 'Service type' },
  { key: 'sharing_type', label: 'Sharing type' },
  { key: 'payment_type', label: 'Payment' },
  { key: 'users.length', label: 'No of staff' },
  { key: 'start_date', label: 'Start date' },
  { key: 'status', label: 'Status' }
]
const sharing_type = ref('')
const date = ref(null)

const fetchRequest = async () => {
	loading.value = true
	requests.value = []
	const { data } = await axiosInstance.get(`/v1/shuttle-requests?page=${currentPage.value}&limit=${perPage.value}&corporate_id=${props.companyId}${sharing_type.value ? `&sharing_type=${sharing_type.value}` : ''}${date.value ? `&created_at=${date.value}` : ''}`)
	requests.value = data.data.length ? data.data : []
	totalRecords.value = data.metadata.total
	loading.value = false
}

watch([currentPage, date, sharing_type], () => {
	fetchRequest()
})

const viewRequest = (data) => {
	router.push(`/request/${data.id}`)
}

fetchRequest()
</script>